import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Icon } from '@seeqdev/qomponents';
import React from 'react';
import { sqTableOverridesApi } from '@/sdk';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { errorToast, successToast } from '@/utilities/toast.utilities';
import { t } from 'i18next';

export interface OverrideRendererProps extends CustomCellRendererProps {
  tableDefinitionId: string;
}

export const OverrideCellRenderer = (props: OverrideRendererProps) => {
  const cellData = props.colDef?.field && props.data[props.colDef.field];
  if (!cellData) {
    return undefined;
  }
  if (cellData.override) {
    return (
      <div>
        {props.valueFormatted}
        <Icon
          icon="fa-times"
          large={true}
          extraClassNames="closeIcon ml5"
          onClick={() => deleteOverride(props)}
          tooltip={t('SCALING.OVERRIDE.DELETE_OVERRIDE_TOOLTIP')}
        />
      </div>
    );
  }

  return <div>{props.valueFormatted}</div>;
};

const deleteOverride = async (props: OverrideRendererProps) => {
  if (props.colDef?.field === undefined || props.api === undefined) {
    return;
  }
  const columns = props.api.getColumns();
  const columnIdItemId = columns
    ?.find((c) => c.getColDef().headerName === SeeqNames.MaterializedTables.ItemIdColumn)
    ?.getColId();

  const columnIdDatumId = columns
    ?.find((c) => c.getColDef().headerName === SeeqNames.MaterializedTables.DatumIdColumn)
    ?.getColId();

  if (!columnIdItemId) {
    return;
  }

  try {
    await sqTableOverridesApi.deleteOverridesFromColumn({
      id: props.tableDefinitionId,
      columnId: props.colDef.field,
      itemId: props.data[columnIdItemId].uuid,
      datumId: columnIdDatumId ? props.data[columnIdDatumId].uuid : undefined,
    });
    successToast({ messageKey: 'SCALING.OVERRIDE.OVERRIDE_DELETE_SUCCESS' });
    props.api.clearFocusedCell();
  } catch (e) {
    errorToast({ httpResponseOrError: e });
  }
};
